import React from 'react';
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { LimitLevel } from '../../../../types';
import { RISK_LEVELS } from '../../../common/constants';
import CellWidthBackground from '../CellWidthBackground';

interface LimitLevelCellParams {
  params: GridRenderCellParams<LimitLevel>;
}

export const limitLevelValueFormatter = (
  params: GridValueFormatterParams,
): string | null => {
  const value = params.value as LimitLevel;

  if (!value) return '';

  const { label } = RISK_LEVELS[value];

  return label;
};

const LimitLevelCell: React.FC<LimitLevelCellParams> = ({ params }) => {
  const value = params.value;

  if (!value) return null;

  const { color, label } = RISK_LEVELS[value];

  return <CellWidthBackground color={color}>{label}</CellWidthBackground>;
};

export default LimitLevelCell;
