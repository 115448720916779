import React from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import InputEditCell from './InputEditCell';

const renderEditNumberCell: GridColDef['renderCell'] = (
  params,
): React.ReactNode => {
  return <InputEditCell {...params} />;
};

export default renderEditNumberCell;
