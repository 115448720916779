import { defineMessages } from 'react-intl';

export default defineMessages({
  dateRange: {
    id: 'Unknown.DateRangeSettings.sport',
    defaultMessage: 'Date Range',
  },
  enable: {
    id: 'Unknown.DateRangeSettings.disable',
    defaultMessage: 'Disable',
  },
  successUpdate: {
    id: 'Exchange.DateRangeSettings.successUpdate',
    defaultMessage:
      'Date range configuration successfully updated, updates will be applied within 1 hour',
  },
  errorUpdate: {
    id: 'Exchange.DateRangeSettings.errorUpdate',
    defaultMessage: 'Error while trying update date range config',
  },
});
