import { defineMessages } from 'react-intl';

export default defineMessages({
  limit: {
    id: 'Exchange.ExchangeSettingsControlsList.limit',
    defaultMessage: 'Control',
  },
  value: {
    id: 'Exchange.ExchangeSettingsControlsList.value',
    defaultMessage: 'Value',
  },
});
