import { DateRangeRawRow } from './useDateRange';
import capitalizeFirstLetter from '../../../common/capitalizeFirstLetter';
import { DATE_RANGE_INTERVALS } from '../../../common/constants';
import { v4 as uuidv4 } from 'uuid';
import { BackofficeUserRole } from '../../../../types';

interface UseSetDateRangeRowsProps {
  rows: DateRangeRawRow[];
  playerId: string | undefined | null;
}

interface DateRangeRow {
  id: string;
  dateRange: string;
  isDisabled: boolean;
  disabledBy?: BackofficeUserRole;
}

const formatRow = (row: DateRangeRawRow): DateRangeRow => {
  const capitalizedDateRange = capitalizeFirstLetter(row.ref);
  const sanitisedDateRange =
    row.ref === 'inPlay' ? 'In Play' : capitalizedDateRange || '';

  return {
    id: row.id,
    dateRange: sanitisedDateRange,
    isDisabled: row.is_disabled,
    disabledBy: row.disabled_by,
  };
};

const findMissingDateRanges = (existingDateRanges: string[]): string[] => {
  return DATE_RANGE_INTERVALS.filter(
    (interval) => !existingDateRanges.includes(interval),
  );
};

const useSetDateRangeRows = (
  props: UseSetDateRangeRowsProps,
): DateRangeRow[] => {
  const { rows, playerId } = props;

  const filteredRows = rows.filter((row) =>
    playerId ? row.player_id === playerId : !row.player_id,
  );

  const formattedRows = filteredRows.map(formatRow);

  const existingDateRanges = formattedRows.map((row) => row.dateRange);
  const missingDateRanges = findMissingDateRanges(existingDateRanges);

  const newRows = missingDateRanges.map((interval) => ({
    id: uuidv4(),
    dateRange: interval,
    isDisabled: false,
  }));

  return [...formattedRows, ...newRows].sort(
    (a, b) =>
      DATE_RANGE_INTERVALS.indexOf(a.dateRange) -
      DATE_RANGE_INTERVALS.indexOf(b.dateRange),
  );
};

export default useSetDateRangeRows;
