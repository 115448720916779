import React from 'react';
import { GridRenderCellParams } from '@miyagami-com/lsx-ui-components';

const renderNumberCell = (
  params: GridRenderCellParams<number>,
): React.ReactNode => {
  const { value } = params;

  if (!value) return value;

  return value;
};

export default renderNumberCell;
