import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  Divider,
  AccordionDetails,
} from '@miyagami-com/lsx-ui-components';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  SettingsIcon,
  PeopleIcon,
  ExpandMoreIcon,
  CompareArrowsIcon,
  QueueIcon,
  AssessmentIcon,
  NearbyError,
} from '../Icons';
import { SYSTEM_ADMIN_ROLE } from '../../../common/constants';
import { RootState } from '../../../../types';

import messages from './messages';
import useStyle from './useStyles';
import getReportUrlByPermission from '../../ProfitLoss/ProfitLossReportPage/getReportUrlByPermission';
import getReportUrlByPermissionForDetailed from '../../ProfitLoss/ProfitLossDetailedPage/getReportUrlByPermission';

import CompressedMenu from './CompressedMenu';

interface RenderItemsProps {
  open: boolean;
  brandId: string;
}

const RenderItems: React.FC<RenderItemsProps> = ({ open, brandId }) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const user = useSelector((root: RootState) => root.user);
  const { permissionMap, unParsedRoles } = user;
  const history = useHistory();
  const classes = useStyle();
  const intl = useIntl();

  const onCompressedMenuClose = () => {
    setAnchorElement(null);
  };

  const items = useMemo(() => {
    const isReadAgentsPermissionGranted =
      !!permissionMap[brandId]?.superAgent?.read ||
      !!permissionMap[brandId]?.masterAgent?.read ||
      !!permissionMap[brandId]?.agent?.read;

    const isReadPlayerPermissionGranted =
      !!permissionMap[brandId]?.player?.read;

    const isReadBackofficeUsersPermissionGranted =
      !!permissionMap[brandId]?.finance?.read ||
      !!permissionMap[brandId]?.support?.read ||
      !!permissionMap[brandId]?.owner?.read ||
      !!permissionMap[brandId]?.marketing?.read;

    const isSystemAdminPermissionGranted =
      unParsedRoles?.includes(SYSTEM_ADMIN_ROLE);

    // const isBrandOwnerUpdatePermissionGranted =
    //   !!permissionMap[brandId]?.brand?.update;

    const isExchangeSettingsPermissionGranted =
      !!permissionMap[brandId]?.exchange?.read &&
      !!permissionMap[brandId]?.exchange?.create &&
      !!permissionMap[brandId]?.exchange?.update;

    const isReadRiskBetsPermissionGranted =
      !!permissionMap[brandId]?.riskBets?.read;

    const systemSlug = 'system';
    const systemMessage = intl.formatMessage(messages.system);
    const settingsIcon = <SettingsIcon fontSize="small" />;

    const renderItemsWithoutBrands = [
      {
        slug: systemSlug,
        name: systemMessage,
        icon: settingsIcon,
        subItems: [
          {
            name: intl.formatMessage(messages.systemAdmins),
            url: '/system-admins',
            viewAccess: isSystemAdminPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.brandOwners),
            url: '/brand-owners',
            viewAccess: isSystemAdminPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.brandOverview),
            url: '/brand-overview',
            viewAccess: !!permissionMap[brandId]?.brand?.read,
          },
        ],
      },
    ];

    const renderItems = [
      {
        slug: 'players',
        name: intl.formatMessage(messages.playersAndAgents),
        icon: <PeopleIcon fontSize="small" />,
        subItems: [
          {
            name: intl.formatMessage(messages.playerOverview),
            url: `/b/${brandId}/player-overview`,
            viewAccess: isReadPlayerPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.agentOverview),
            url: `/b/${brandId}/agent-overview`,
            viewAccess: isReadAgentsPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.duplicatePlayers),
            url: `/b/${brandId}/duplicate-players`,
            viewAccess: isSystemAdminPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.manualRegistration),
            url: `/b/${brandId}/manual-registration`,
            viewAccess:
              isReadPlayerPermissionGranted || isReadAgentsPermissionGranted,
          },
        ],
      },
      {
        slug: 'exchange',
        name: intl.formatMessage(messages.exchange),
        icon: <CompareArrowsIcon fontSize="small" />,
        subItems: [
          {
            name: intl.formatMessage(messages.bettingOverview),
            url: `/b/${brandId}/betting-overview`,
            viewAccess: !!permissionMap[brandId]?.bet?.read,
          },
          {
            name: intl.formatMessage(messages.eventOverview),
            url: `/b/${brandId}/event-overview`,
            viewAccess: !!permissionMap[brandId]?.event?.read,
          },
          // {
          //   name: intl.formatMessage(messages.pastEvents),
          //   url: `/b/${brandId}/past-events`,
          //   viewAccess: !!permissionMap[brandId]?.event?.read,
          // },
          {
            name: intl.formatMessage(messages.exchangeSettings),
            url: `/b/${brandId}/exchange-settings`,
            viewAccess:
              isExchangeSettingsPermissionGranted ||
              isSystemAdminPermissionGranted,
          },
        ],
      },
      {
        slug: 'reports',
        name: intl.formatMessage(messages.reports),
        icon: <AssessmentIcon fontSize="small" />,
        subItems: [
          {
            name: intl.formatMessage(messages.PLReport),
            url: getReportUrlByPermission({ user, brandId }),
            viewAccess: isReadAgentsPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.PLReportDetailed),
            url: getReportUrlByPermissionForDetailed({ user, brandId }),
            viewAccess: isReadAgentsPermissionGranted,
          },
          {
            name: intl.formatMessage(messages.betExport),
            url: `/b/${brandId}/bet-export`,
            viewAccess: isReadAgentsPermissionGranted,
          },
        ],
      },
      {
        slug: 'riskbets',
        name: 'Risk bets',
        icon: <NearbyError fontSize="small" />,
        subItems: [
          {
            name: 'Risk bets',
            url: `/risk-bets`,
            viewAccess: isReadRiskBetsPermissionGranted,
          },
        ],
      },
      {
        slug: 'cms',
        name: intl.formatMessage(messages.cms),
        icon: <QueueIcon fontSize="small" />,
        subItems: [
          {
            name: intl.formatMessage(messages.themeSettings),
            url: `/b/${brandId}/theme-settings`,
            viewAccess: !!permissionMap[brandId]?.theme?.read,
          },
          {
            name: intl.formatMessage(messages.internalization),
            url: '/internalization',
            viewAccess: !!permissionMap[brandId]?.language?.read,
          },

          {
            name: intl.formatMessage(messages.widgets),
            url: `/b/${brandId}/widgets`,
            viewAccess: !!permissionMap[brandId]?.widget?.read,
          },
        ],
      },
      {
        slug: systemSlug,
        name: systemMessage,
        icon: settingsIcon,
        subItems: [
          ...renderItemsWithoutBrands[0].subItems,
          {
            name: intl.formatMessage(messages.backofficeUsers),
            url: `/b/${brandId}/backoffice-users`,
            viewAccess: isReadBackofficeUsersPermissionGranted,
          },
          // this page is mocked
          // {
          //   name: intl.formatMessage(messages.performance),
          //   url: `/b/${brandId}/performance`,
          //   viewAccess: !!permissionMap[brandId]?.system?.read,
          // },
          {
            name: intl.formatMessage(messages.security),
            url: `/b/${brandId}/security`,
            viewAccess:
              !!permissionMap[brandId]?.brand?.read ||
              !!permissionMap[brandId]?.systemAdmin?.read,
          },
          {
            name: intl.formatMessage(messages.systemLogs),
            url: `/b/${brandId}/system-logs`,
            viewAccess: isReadBackofficeUsersPermissionGranted,
          },
        ],
      },
    ];

    const actualItems = brandId ? renderItems : renderItemsWithoutBrands;
    const filteredItems = actualItems.map(({ subItems, ...otherParams }) => {
      const filteredSubItems = subItems.filter(
        ({ viewAccess }) => !!viewAccess,
      );

      return { subItems: filteredSubItems, ...otherParams };
    });

    return filteredItems;
  }, [brandId, intl, permissionMap, unParsedRoles, user]);

  return (
    <>
      {items.map((item) => {
        if (!item.subItems.length) return;

        const menuItemId = `accordion-${item.slug}`;

        return (
          <Accordion
            defaultExpanded={item.subItems.some((objSubItem) =>
              history.location.pathname.includes(objSubItem.url),
            )}
            key={item.slug}
            classes={{
              root: classNames(classes.whiteColor, classes.accordion),
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.whiteColor} />}
              aria-controls={menuItemId}
              onClick={({ currentTarget }) => setAnchorElement(currentTarget)}
              id={menuItemId}
            >
              <Box
                height="33px"
                px={2}
                py={1.7}
                display="flex"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" pr={2}>
                  {item.icon}
                </Box>
                {open && (
                  <Box>
                    <Typography>{item.name}</Typography>
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            {open ? (
              <AccordionDetails>
                {item.subItems.map((subItem, index) => (
                  <React.Fragment key={subItem.url}>
                    <Box
                      className={classNames(classes.onHoverBgColorGrey600, {
                        [classes.bgColorGrey600]:
                          history.location.pathname.includes(subItem.url),
                      })}
                      px={2}
                      py={1.6}
                      bgcolor="grey.700"
                      onClick={() => {
                        history.replace(subItem?.url);
                      }}
                    >
                      <Typography>{subItem.name}</Typography>
                    </Box>
                    {index !== item.subItems.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </AccordionDetails>
            ) : (
              <CompressedMenu
                anchorElement={anchorElement}
                onClose={onCompressedMenuClose}
                labeledBy={menuItemId}
                menuItems={item.subItems}
              />
            )}
          </Accordion>
        );
      })}
    </>
  );
};

export default RenderItems;
