import {
  Box,
  CircularProgress,
  DataGridPremiumProps,
  SimpleDataGridPremium,
} from '@miyagami-com/lsx-ui-components';
import useColumns from './useColumns';
import useOnCellEditCommit from './useOnCellEditCommit';
import useBrandId from '../../../common/hooks/useBrandId';
import useDateRange from './useDateRange';
import useSetDateRangeRows from './useSetDateRangeRows';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { useSelector } from 'react-redux';
import { RootState, BackofficeUserRole } from '../../../../types';
import { ROLES_HIERARCHY } from '../../../common/constants';

interface DateRangeProps
  extends Omit<DataGridPremiumProps, 'columns' | 'rows'> {
  playerId?: string;
}

const DateRangeSettings: React.FC<DateRangeProps> = (props) => {
  const { playerId } = props;
  const brandId = useBrandId();

  const columns = useColumns();

  const rowHeight = 40;
  const [rawRows, isLoading] = useDateRange({ brandId, playerId });
  const formattedRows = useSetDateRangeRows({ rows: rawRows, playerId });

  // HIERARCHY CODE //
  const { roles } = useSelector((root: RootState) => root.user);
  const isSystemAdmin = checkIsSystemAdmin({ roles });

  let role = roles?.find((stateRole) => stateRole.brand_id === brandId)
    ?.role_id as BackofficeUserRole;

  role = (isSystemAdmin ? 'systemAdmin' : role) as BackofficeUserRole;

  const userRoleLevel = ROLES_HIERARCHY[role];

  const filteredRows = formattedRows.filter((row) => {
    const { disabledBy, isDisabled } = row;

    if (disabledBy === undefined || !isDisabled) {
      return true;
    }

    const disabledByLevel = ROLES_HIERARCHY[disabledBy];
    return disabledByLevel <= userRoleLevel;
  });
  // END HIERARCHY //

  const onCellEditCommit = useOnCellEditCommit(
    { brandId, role, playerId },
    filteredRows,
  );

  if (isLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box height={1} display="flex">
        <SimpleDataGridPremium
          columns={columns}
          rows={filteredRows || []}
          rowHeight={rowHeight}
          autoHeight
          disableSelectionOnClick
          onCellEditCommit={onCellEditCommit}
        />
      </Box>
    </>
  );
};

export default DateRangeSettings;
