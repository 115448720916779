import { makeStyles } from '@mui/styles';

import { borderWidth } from '../../../common/theme';

const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'absolute',
    borderBottomRightRadius: borderWidth.l,
    borderBottomLeftRadius: borderWidth.l,
  },
}));

export default useStyles;
