import { useQuery } from 'react-query';
import { BrandLimit } from '../../../types/supabase';
import useSupabase from './useSupabase';

interface UseBrandLimitsParams {
  brandId: string;
  select?: string;
}

const useBrandLimits = <T extends BrandLimit>(
  params: UseBrandLimitsParams,
): typeof queryResult => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T[] | null, Error>(
    ['brandLimits', brandId],
    async () => {
      const response = await supabase
        .from('brand_limits')
        .select<typeof select, T>(select)
        .eq('brand_id', brandId)
        .throwOnError();

      return response.data;
    },
  );

  return queryResult;
};

export default useBrandLimits;
