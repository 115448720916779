import React from 'react';
import { Box, Paper, Tabs, Tab } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
} from 'react-router';

import PlayerExchangeSportline from '../PlayerExchangeSportline';
import ExchangeBetOverview from '../../ExchangeBet/ExchangeBetOverview';
import ProfitLossReportTable from '../../ProfitLoss/ProfitLossReportTable';

import messages from './messages';
import useStyles from './useStyles';
import ExchangeSettingsDateRangeTab from '../../Exchange/ExchangeSettingsDateRangeTab';

type RouteMatch = {
  url: string;
};

interface PlayerExchangePageProps {
  params: {
    exchangeTab: string;
    brandId: string;
    userId: string;
    userTab: string;
  };
}

const PlayerExchangePage: React.FC<PlayerExchangePageProps> = ({ params }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { url }: RouteMatch = useRouteMatch();
  const { exchangeTab, brandId, userId, userTab } = params;

  const tabList = [
    {
      label: intl.formatMessage(messages.bets),
      value: 'bets',
      component: <ExchangeBetOverview pageType="player" />,
    },
    {
      label: intl.formatMessage(messages.sportline),
      value: 'sportline',
      component: <PlayerExchangeSportline playerId={userId} />,
      isHaveAccess: true,
    },
    {
      label: intl.formatMessage(messages.profitLoss),
      value: 'profit-loss',
      component: <ProfitLossReportTable playerId={userId} />,
    },
    {
      label: intl.formatMessage(messages.dateRange),
      value: 'date-range',
      component: (
        <ExchangeSettingsDateRangeTab
          isPlayerExchangePage={true}
          playerId={userId}
        />
      ),
    },
  ];

  const onChangeTab = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    history.push(`/b/${brandId}/player-overview/${userId}/${userTab}/${value}`);
  };

  return (
    <Box pt={4}>
      <Paper>
        <Tabs
          value={exchangeTab || false}
          onChange={onChangeTab}
          textColor="inherit"
          variant="fullWidth"
        >
          {tabList.map(({ label, value }) => (
            <Tab
              key={value}
              className={classes.tabsBorder}
              label={label}
              value={value}
            />
          ))}
        </Tabs>
        <Switch>
          {tabList.map(({ value, component }) => (
            <Route
              key={value}
              path={`/b/:brandId/player-overview/:userId/:userTab/${value}`}
            >
              {component}
            </Route>
          ))}
          <Route path={`/b/:brandId/player-overview/:userId/:userTab`}>
            <Redirect to={`${url}/${tabList[0].value}`} />
          </Route>
        </Switch>
      </Paper>
    </Box>
  );
};

export default PlayerExchangePage;
