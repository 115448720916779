import { useQuery } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { BackofficeUserRole } from '../../../../types';

interface UseSportLineProps {
  brandId: string;
  playerId?: string;
}

export interface DateRangeRawRow {
  id: string;
  ref: string;
  is_disabled: boolean;
  disabled_by: BackofficeUserRole;
  player_id: string | null;
}

interface ResponseData {
  data: DateRangeRawRow[];
}

const useDateRange = ({
  brandId,
  playerId,
}: UseSportLineProps): [DateRangeRawRow[], boolean] => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const fetchDateRangeConfigCloud = httpsCallable(
    functions,
    'back-exchangeConfig-callFetchDateRangeConfig',
  );

  const fetchDateRangeConfig = async () => {
    const response = await fetchDateRangeConfigCloud({ brandId, playerId });
    return (response.data as ResponseData).data;
  };

  const { data: rows, isLoading } = useQuery<DateRangeRawRow[], Error>(
    ['dateRangeConfig', brandId, playerId],
    fetchDateRangeConfig,
  );

  return [rows || [], isLoading];
};

export default useDateRange;
