import { GridRowData } from '@mui/x-data-grid';
import shallowEqual from '../../../common/shallowEqual';

interface CheckEqualsRowsParams {
  rows: GridRowData[];
  field: string;
  equalObject: GridRowData;
}

const stakeWinFields = ['maxStakeAmount', 'maxWinAmount'];

const checkEqualsRows = ({
  rows,
  field,
  equalObject,
}: CheckEqualsRowsParams): boolean => {
  const rowsWithoutIds = rows.map(
    ({
      id: _id,
      maxStakeAmount: maxStake,
      maxWinAmount: maxAmount,
      ...otherValues
    }) => {
      return otherValues;
    },
  );

  if (!stakeWinFields.includes(field)) {
    const isEqual = rowsWithoutIds.some((object) => {
      return shallowEqual(object, equalObject);
    });

    return isEqual;
  }

  return false;
};

export default checkEqualsRows;
