import { FC } from 'react';
import { Box } from '@miyagami-com/lsx-ui-components';

interface CellWidthBackgroundProps {
  color: string;
}

const CellWidthBackground: FC<CellWidthBackgroundProps> = ({
  color,
  children,
}) => {
  return (
    <Box
      bgcolor={color}
      mx={-2}
      px={2}
      width="fill-available"
      color="common.white"
      py={2}
    >
      {children}
    </Box>
  );
};

export default CellWidthBackground;
