import React, { useState, useContext, useMemo } from 'react';
import { CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { FormikHelpers } from 'formik';

import {
  BrandWidget,
  BrandWidgetPosition,
  BrandWidgetStatus,
} from '../../../../types';

import { DEFAULT_REGION } from '../../../common/constants';

import { DropzoneFile } from '../../Unknown/ImageUpload';

import messages from './messages';
import WidgetDetailsForm from './WidgetDetailsForm';
import useUploadFile from '../../../common/hooks/useUploadFile';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';

export type WidgetDetailsFormValues = {
  id: string;
  title: string;
  description: string;
  brandId: string | null;
  link: string;
  image: DropzoneFile | null;
  status: string;
  position: BrandWidgetPosition;
};

interface UpdateWidgetParams {
  id: string;
  title: string;
  description: string;
  link: string;
  brandId: string;
  image: string | null;
  position: BrandWidgetPosition;
}

interface WidgetDetailsProps {
  widget: BrandWidget;
  brandId: string | null;
}

const WidgetDetails: React.FC<WidgetDetailsProps> = ({
  widget,
  brandId: defaultBrandId,
}) => {
  const intl = useIntl();

  const uploadFile = useUploadFile();

  const initialValues: WidgetDetailsFormValues = useMemo(() => {
    const { image_link, ...widgetParams } = widget;

    return {
      ...widgetParams,
      image: null,
      brandId: defaultBrandId,
    };
  }, [defaultBrandId, widget]);

  const { setAlert } = useContext(UIContext);

  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    useState<boolean>(false);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryClient = useQueryClient();

  const onSubmit = async (
    { image, description, link, position, title }: WidgetDetailsFormValues,
    { setSubmitting }: FormikHelpers<WidgetDetailsFormValues>,
  ) => {
    const updateWidget = httpsCallable(functions, 'back-widget-updateWidget');

    try {
      setSubmitting(true);

      const updateWidgetParams: UpdateWidgetParams = {
        image: null,
        brandId: widget.brand_id,
        description,
        id: widget.id,
        link,
        position,
        title,
      };

      if (image) {
        const imageURL = await uploadFile(
          `brands/${widget.brand_id}/widgets/${widget.id}`,
          image,
          {
            contentType: image.type,
          },
        );

        updateWidgetParams.image = imageURL;
      }

      await updateWidget(updateWidgetParams);

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpdatedWidget),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUpdatedWidget),
      });
    } finally {
      setSubmitting(false);
      queryClient.refetchQueries(['brandWidget', widget.id]);
    }
  };

  const onUpdateStatus = async (status: Omit<BrandWidgetStatus, 'draft'>) => {
    setIsLoadingUpdateStatus(true);
    const updateWidgetStatus = httpsCallable(
      functions,
      'back-widget-updateWidgetStatus',
    );

    try {
      await updateWidgetStatus({
        brandId: defaultBrandId,
        widgetId: widget.id,
        status,
      });

      const isArchiveStatus = status === 'archive';

      const message = isArchiveStatus
        ? messages.successArchive
        : messages.successPublish;

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(message),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'warning',
        message: intl.formatMessage(messages.errorPublish),
      });
    } finally {
      setIsLoadingUpdateStatus(false);
      queryClient.refetchQueries(['brandWidget', widget.id]);
    }
  };

  const isPublished = widget.status === 'published';

  return (
    <CollapseBox
      defaultValue={true}
      label={intl.formatMessage(messages.widgetDetails)}
    >
      <WidgetDetailsForm
        imageLink={widget.image_link}
        initialValues={initialValues}
        onSubmit={onSubmit}
        isLoadingUpdateStatus={isLoadingUpdateStatus}
        onUpdateStatus={onUpdateStatus}
        isPublished={isPublished}
      />
    </CollapseBox>
  );
};

export default WidgetDetails;
