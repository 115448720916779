import { IntlShape } from 'react-intl';

import { BackOfficeUser, ExtendedTransaction } from '../../../../types';

import messages from './messages';

export type TransformTableDataParams = {
  data?: ExtendedTransaction[] | null;
  userId: string;
  users?: BackOfficeUser[];
  intl: IntlShape;
};

export type NormalizedTransaction = {
  id: string;
  date: string | Date;
  username: string;
  amount: number;
  balance: number;
  exposure: number;
  note: string;
  receiver: string;
};

const normalizeRows = (
  params: TransformTableDataParams,
): NormalizedTransaction[] => {
  const { data, userId, users, intl } = params;

  if (!data) return [];

  const userMap = users?.reduce((map, user) => {
    map.set(user.id, user);

    return map;
  }, new Map<string, BackOfficeUser>());

  const normalizedData = data.map<NormalizedTransaction>(
    (transactionEntity) => {
      const { created_at, id, participants, note, sender, receiver, amount } =
        transactionEntity;

      let currentUserId = '';
      let balance = 0;
      let exposure = 0;

      let trueAmount = amount;

      const date = new Date(created_at);

      const [senderId, receiverId] = participants.split('/');

      const currentUserIsSender = senderId === userId;

      if (currentUserIsSender) {
        currentUserId = receiverId || '';
        balance = +sender?.balance.toFixed(2);
        exposure = +sender?.exposure.toFixed(2);
        trueAmount = +(-1 * amount).toFixed(2);
      } else {
        currentUserId = senderId || '';
        balance = +receiver?.balance.toFixed(2);
        exposure = +receiver?.exposure.toFixed(2);
      }

      const currentAgent = userMap?.get(userId);

      const receiverAgent = userMap?.get(currentUserId);

      const username =
        currentAgent?.username || intl.formatMessage(messages.system);

      const receiverUsername = !receiverAgent
        ? 'Player'
        : receiverAgent?.username || intl.formatMessage(messages.system);

      const transaction: NormalizedTransaction = {
        id,
        exposure,
        amount: +trueAmount.toFixed(2),
        username,
        receiver: receiverUsername,
        date,
        note,
        balance,
      };
      return transaction;
    },
  );

  const filteredData = normalizedData.filter(
    (transaction) => transaction.amount,
  );

  return filteredData;
};

export default normalizeRows;
