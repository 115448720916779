import { MutableRefObject } from 'react';
import { GridApi, GridCellEditCommitParams } from '@mui/x-data-grid';
import { NormalizedLimitLevel } from './normalizeLimitLevelsData';

interface GetCurrentRowParams {
  params: GridCellEditCommitParams;
  dataGridApi: MutableRefObject<GridApi | undefined>;
}

const getCurrentRow = ({
  params,
  dataGridApi,
}: GetCurrentRowParams): NormalizedLimitLevel => {
  const { id: rowId, value, field } = params;

  if (!dataGridApi.current) throw new Error('Failed update cell.');

  const row = dataGridApi.current.getRow(rowId);

  const updatedRow = {
    [field]: value,
  };

  const currentRow = { ...row, ...updatedRow } as NormalizedLimitLevel;

  return currentRow;
};

export default getCurrentRow;
