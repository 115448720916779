import { ExchangeConfig, ExchangeConfigOwnerType } from '../../../../types';
import createExchangeConfigTypeBySportLineType from './createExchangeConfigTypeBySportLineType';
import getEventId from './getEventId';
import { SportLineRow } from './useColumns';

interface ConvertSportLineRowToExchangeConfigParams {
  sportLineRow: SportLineRow;
  playerId?: string;
  brandId: string;
}

const convertSportLineRowToExchangeConfig = (
  params: ConvertSportLineRowToExchangeConfigParams,
): Omit<ExchangeConfig, 'id'> => {
  const { sportLineRow, brandId, playerId } = params;

  const ownerType: ExchangeConfigOwnerType = playerId ? 'player' : 'brand';

  const isEvent = sportLineRow.type === 'EVENT';

  const ref = isEvent ? getEventId({ id: sportLineRow.id }) : sportLineRow.id;

  const type = createExchangeConfigTypeBySportLineType({ row: sportLineRow });

  const exchangeConfig: Omit<ExchangeConfig, 'id'> = {
    brand_id: brandId,
    player_id: playerId || null,
    owner_type: ownerType,
    is_disabled: !sportLineRow.isEnabled,
    priority: sportLineRow.priority,
    risk_level: sportLineRow.riskLevel,
    ref,
    type,
  };

  return exchangeConfig;
};

export default convertSportLineRowToExchangeConfig;
