import { InsertBrandLimit } from '../../../../types/supabase';
import { NormalizedLimitLevel } from './normalizeLimitLevelsData';

interface ConvertRowToBrandLimitParams {
  row: NormalizedLimitLevel;
}

const convertRowToBrandLimit = (
  params: ConvertRowToBrandLimitParams,
): InsertBrandLimit => {
  const { row } = params;

  const insertBrandLimit: InsertBrandLimit = {
    brand_id: row.brandId,
    competition_risk_level: row.leagueLimitLevel,
    event_risk_level: 'low',
    event_type_risk_level: row.sportLimitLevel,
    market_risk_level: row.marketTypeLimitLevel,
    max_stake_amount: row.maxStakeAmount,
    max_win_amount: row.maxWinAmount,
    player_risk_level: row.playerLimitLevel,
  };

  return insertBrandLimit;
};

export default convertRowToBrandLimit;
