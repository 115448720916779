// eslint-disable-next-line @typescript-eslint/ban-types
const shallowEqual = <T extends {}>(
  firstObject: T,
  secondObject: T,
): boolean => {
  const firstKeys = Object.keys(firstObject) as (keyof typeof firstObject)[];
  const secondKeys = Object.keys(secondObject) as (keyof typeof secondObject)[];
  if (firstKeys.length !== secondKeys.length) {
    return false;
  }
  for (const key of firstKeys) {
    if (firstObject[key] !== secondObject[key]) {
      return false;
    }
  }
  return true;
};

export default shallowEqual;
