import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.id',
    defaultMessage: 'ID',
  },
  sport: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.sport',
    defaultMessage: 'Sport',
  },
  league: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.league',
    defaultMessage: 'League',
  },
  type: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.type',
    defaultMessage: 'Type',
  },
  player: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.player',
    defaultMessage: 'Player',
  },
  maxStake: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.maxStake',
    defaultMessage: 'Max stake',
  },
  maxWin: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.maxWin',
    defaultMessage: 'Max win',
  },
  successUpdating: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.successUpdating',
    defaultMessage: 'Limits was successfully updated!',
  },
  errorUpdating: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.errorUpdating',
    defaultMessage: 'An error occurred while updating the config',
  },
  alreadyExists: {
    id: 'ExchangeSettings.ExchangeSettingsLimitsOverview.alreadyExists',
    defaultMessage: 'This row already exists',
  },
});
