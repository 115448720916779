import { FC, useState, useContext, useMemo } from 'react';
import {
  UIContext,
  Backdrop,
  CircularProgress,
} from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useFirebaseApp } from 'reactfire';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../types';
import {
  DEFAULT_LIMIT,
  DEFAULT_REGION,
  ONLY_POSITIVE_AMOUNT_ERROR,
} from '../../../common/constants';
import checkBalanceAmountOnlyPositive from '../../../common/checkBalanceAmountOnlyPositive';
import HeaderWithFilter from '../../Unknown/HeaderWithFilter';
import ExchangeSettingsControlsList, {
  UpdateControlsParams,
} from '../ExchangeSettingsControlsList';

import messages from './messages';
import useStyles from './useStyles';
import useBrandControl from '../../../common/hooks/useBrandControl';
import { getFunctions, httpsCallable } from 'firebase/functions';

type QueryParams = {
  brandId: string;
};

const ExchangeSettingsControls: FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { brandId }: QueryParams = useParams();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const { brands } = useSelector((state: RootState) => state.globalData);
  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const { data: brandControl, isLoading } = useBrandControl({ brandId });

  const onUpdateControls = async ({
    limitKey,
    limitValue,
  }: UpdateControlsParams) => {
    const updateControlsFunction = httpsCallable(
      functions,
      'back-control-updateBrandControls',
    );

    try {
      setIsLoadingData(true);
      checkBalanceAmountOnlyPositive(limitValue);

      await updateControlsFunction({
        brandId,
        limitKey,
        limitValue,
      });

      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.updateControlsSuccess),
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message =
        ONLY_POSITIVE_AMOUNT_ERROR === error.message
          ? messages.maxAmountError
          : messages.updateControlsError;

      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(message),
      });
    } finally {
      setIsLoadingData(false);
    }
  };

  const rowList = useMemo(() => {
    if (brandControl && !isLoading) {
      const maxStakeNumberHighOdd = '7.00';
      const maxStakeMinNumberLowOdd = '1.01';
      const maxStakeMaxNumberLowOdd = '1.17';
      return [
        {
          id: 'matchedVolume',
          type: intl.formatMessage(messages.matchedVolume),
          limit: brandControl.matched_volume || DEFAULT_LIMIT,
        },
        {
          id: 'liquidityDepth',
          type: intl.formatMessage(messages.liquidityDepth),
          limit: brandControl.liquidity_depth || DEFAULT_LIMIT,
        },
        {
          id: 'backPayoutMargin',
          type: intl.formatMessage(messages.backPayoutMargin),
          limit: brandControl.back_payout_margin || DEFAULT_LIMIT,
        },
        {
          id: 'layPayoutMargin',
          type: intl.formatMessage(messages.layPayoutMargin),
          limit: brandControl.lay_payout_margin || DEFAULT_LIMIT,
        },
        {
          id: 'layMaxStakeInPlay.lowOdd',
          type: intl.formatMessage(messages.layMaxStakeInPlayLowOdd, {
            maxStakeMinNumberLowOdd,
            maxStakeMaxNumberLowOdd,
          }),
          limit: brandControl.lay_max_stake_in_play_low_odds || DEFAULT_LIMIT,
        },
        {
          id: 'layMaxStakeInPlay.highOdd',
          type: intl.formatMessage(messages.layMaxStakeInPlayHighOdd, {
            maxStakeNumberHighOdd,
          }),
          limit: brandControl.lay_max_stake_in_play_high_odds || DEFAULT_LIMIT,
        },
        {
          id: 'backMaxStakeInPlay.lowOdd',
          type: intl.formatMessage(messages.backMaxStakeInPlayLowOdd, {
            maxStakeMinNumberLowOdd,
            maxStakeMaxNumberLowOdd,
          }),
          limit: brandControl.back_max_stake_in_play_low_odds || DEFAULT_LIMIT,
        },
        {
          id: 'backMaxStakeInPlay.highOdd',
          type: intl.formatMessage(messages.backMaxStakeInPlayHighOdd, {
            maxStakeNumberHighOdd,
          }),
          limit: brandControl.back_max_stake_in_play_high_odds || DEFAULT_LIMIT,
        },
      ];
    }

    return [];
  }, [brandControl, isLoading, intl]);

  return (
    <>
      <HeaderWithFilter
        name={intl.formatMessage(messages.controls)}
        brands={brands}
      />
      <ExchangeSettingsControlsList
        rows={rowList}
        isLoading={isLoading}
        onUpdateControls={onUpdateControls}
      />
      <Backdrop open={isLoadingData} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default ExchangeSettingsControls;
