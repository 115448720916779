import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@miyagami-com/lsx-ui-components';
import { GridRowData } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';

import HeaderWithFilter from '../../Unknown/HeaderWithFilter';

import ExchangeSettingsLimitsOverview from '../ExchangeSettingsLimitsOverview';

import messages from './messages';
import useStyles from './useStyles';

interface ExchangeSettingLimitsTabProps {
  brandId: string;
}

const ExchangeSettingLimitsTab: React.FC<ExchangeSettingLimitsTabProps> = ({
  brandId,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [defaultRows, setDefaultRows] = useState<GridRowData[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);

  useEffect(() => {
    setRows(defaultRows);
  }, [defaultRows, setRows]);

  const { brands } = useSelector((root: RootState) => root.globalData);

  return (
    <>
      <HeaderWithFilter
        name={intl.formatMessage(messages.headerLabel)}
        brands={brands}
        defaultRows={defaultRows}
        setRows={setRows}
      />
      <ExchangeSettingsLimitsOverview
        brandId={brandId}
        rows={rows}
        setDefaultRows={setDefaultRows}
        onLoadingDataChange={setIsLoadingData}
      />
      <Backdrop open={isLoadingData} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default ExchangeSettingLimitsTab;
