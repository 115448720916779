import React, { useMemo, useState } from 'react';
// import { UIContext } from '@miyagami-com/lsx-ui-components';
import {
  GridRowData,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
// import { useFirebaseApp } from 'reactfire';
import format from 'date-fns/format';

import { DEFAULT_DATE_FORMAT } from '../../../common/constants';
import useBrandId from '../../../common/hooks/useBrandId';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import getFromToNumbers from '../../../common/getFromToNumbers';

import DefaultLayout from '../../Unknown/DefaultLayout';
import BrandOverviewAddition from '../BrandOverviewAddition';
import ActionsBox from '../../Unknown/ActionsBox';
import OverviewLayout from '../../Unknown/OverviewLayout';

import BrandOverviewList from '../BrandOverviewList';

import messages from './messages';
// import useRefetchBrand from '../../../common/hooks/useRefetchBrand';

const BrandOverviewPage: React.FC = () => {
  const history = useHistory();
  const brandId = useBrandId();

  // const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const { grantedPermissions } = useVerifyPermissions([
    `brand/${brandId}/brand/create`,
  ]);

  // const refetchBrands = useRefetchBrand();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [defaultRows, setDefaultRows] = useState<GridRowData[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);

  // const functions = useFirebaseApp().functions(DEFAULT_REGION);

  const onPushToBrandPage = (id: string) => {
    history.push(`/brand-overview/${id}`);
  };

  const onCloseAddition = () => {
    setIsOpenModal(false);
  };

  const { from, to } = useMemo(
    () => getFromToNumbers({ list: defaultRows }),
    [defaultRows],
  );

  const isCreatePermissionGranted = !!grantedPermissions.brand?.create;

  const onClickAdd = isCreatePermissionGranted
    ? () => setIsOpenModal(true)
    : undefined;

  // const onDelete = async (deletedBrandID: string) => {
  //   const deleteBrand = functions.httpsCallable('back-brand-deleteBrand');
  //   try {
  //     await deleteBrand({
  //       brandId: deletedBrandID,
  //     });

  //     setAlert({
  //       show: true,
  //       severity: 'success',
  //       message: intl.formatMessage(messages.successfullyDeleteBrand),
  //     });
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   } catch (error: any) {
  //     setAlert({
  //       show: true,
  //       severity: 'warning',
  //       message: error.toString(),
  //     });
  //   } finally {
  //     refetchBrands();
  //   }
  // };

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      hide: true,
      editable: false,
      filterable: false,
    },
    {
      field: 'name',
      headerName: intl.formatMessage(messages.name),
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'admin',
      headerName: intl.formatMessage(messages.administrator),
      editable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'domainsCount',
      headerName: intl.formatMessage(messages.domains),
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'pl',
      headerName: intl.formatMessage(messages.pl),
      editable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: 'commission',
      headerName: intl.formatMessage(messages.netCommission),
      editable: false,
      filterable: false,
      flex: 0.6,
    },
    {
      field: 'directPlayerComission',
      headerName: intl.formatMessage(messages.directPlayerComission),
      editable: false,
      filterable: false,
      flex: 0.7,
    },
    {
      field: 'status',
      headerName: intl.formatMessage(messages.status),
      editable: false,
      filterable: false,
      flex: 0.4,
    },
    {
      field: 'actions',
      headerName: intl.formatMessage(messages.actions),
      sortable: false,
      filterable: false,
      groupable: false,
      flex: 0.4,
      renderCell: (params: GridRenderCellParams) => {
        const id = params.row.id;

        const actions = [
          {
            label: intl.formatMessage(messages.editBrand),
            buttonProps: {
              onClick: () => onPushToBrandPage(id),
            },
          },
        ];

        return <ActionsBox actions={actions} />;
      },
    },
  ];

  const path = [
    { label: intl.formatMessage(messages.system) },
    { label: intl.formatMessage(messages.brandOverview) },
  ];

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: intl.formatMessage(messages.brandOverview),
          from: format(from, DEFAULT_DATE_FORMAT),
          to: format(to, DEFAULT_DATE_FORMAT),
          defaultRows: defaultRows,
          setRows: setRows,
          onClickAdd: onClickAdd,
          tooltipAdd: intl.formatMessage(messages.addBrand),
          withBrandFilter: false,
        }}
      >
        <BrandOverviewList
          columns={columns}
          setRows={setRows}
          rows={rows}
          setDefaultRows={setDefaultRows}
        />
      </OverviewLayout>
      {isCreatePermissionGranted && (
        <BrandOverviewAddition isOpen={isOpenModal} onClose={onCloseAddition} />
      )}
    </DefaultLayout>
  );
};

export default BrandOverviewPage;
