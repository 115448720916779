import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  GridColumns,
  GridRowData,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridEventListener,
  GridEvents,
} from '@mui/x-data-grid';

import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';

import InputCell from '../../Unknown/InputCell';
import DataGridTable from '../../Unknown/DataGridTable';

import messages from './messages';

const propertiesColumn = {
  editable: false,
  filterable: false,
  flex: 0.5,
};

export type UpdateControlsParams = {
  limitKey: string;
  limitValue: number;
};

interface ExchangeSettingsControlsListProps {
  rows: GridRowData[];
  isLoading: boolean;
  onUpdateControls: ({ limitKey, limitValue }: UpdateControlsParams) => void;
}

const ExchangeSettingsControlsList: FC<ExchangeSettingsControlsListProps> = ({
  rows,
  isLoading,
  onUpdateControls,
}) => {
  const intl = useIntl();
  const { isSuspended, isBrandSuspended } = useVerifyPermissions();

  const isDisabled = isSuspended || isBrandSuspended;

  const defaultRenderCell = ({
    api: dataGridApi,
    id,
    value,
    field,
  }: GridRenderCellParams | GridRenderEditCellParams) => {
    const isPayoutMargin =
      id === 'backPayoutMargin' || id === 'layPayoutMargin';

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const maxAmount = Number(e.target.value);

      dataGridApi.setEditCellValue({ id, field, value: maxAmount });
    };

    return (
      <InputCell
        type="number"
        disabled={isDisabled}
        InputProps={{
          inputProps: {
            min: 0,
            step: isPayoutMargin ? 0.01 : 1,
          },
        }}
        onChange={onChange}
        value={value}
      />
    );
  };

  const columns: GridColumns = [
    {
      ...propertiesColumn,
      field: 'type',
      headerName: intl.formatMessage(messages.limit),
    },
    {
      ...propertiesColumn,
      field: 'limit',
      headerName: intl.formatMessage(messages.value),
      renderCell: defaultRenderCell,
      renderEditCell: defaultRenderCell,
    },
  ];

  const onCellEditCommit: GridEventListener<GridEvents.cellEditCommit> = (
    params,
  ) => {
    const prevValue = (params as GridRenderCellParams).formattedValue;
    if (prevValue !== params.value) {
      onUpdateControls({
        limitKey: String(params.id),
        limitValue: Number(params.value),
      });
    }
  };

  return (
    <DataGridTable
      loading={isLoading}
      tableKey="exchange-settings-list"
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      disableColumnPinning
      disableRowGrouping
      autoHeight
      rowHeight={35}
      headerHeight={35}
      onCellEditCommit={onCellEditCommit}
      hideFooter
    />
  );
};

export default ExchangeSettingsControlsList;
