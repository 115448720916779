import { defineMessages } from 'react-intl';

export default defineMessages({
  controls: {
    id: 'Exchange.ExchangeSettingsControls.controls',
    defaultMessage: 'Controls',
  },
  matchedVolume: {
    id: 'Exchange.ExchangeSettingsControls.matchedVolume',
    defaultMessage: 'Matched volume',
  },
  liquidityDepth: {
    id: 'Exchange.ExchangeSettingsControls.liquidityDepth',
    defaultMessage: 'Liquidity depth',
  },
  backPayoutMargin: {
    id: 'Exchange.ExchangeSettingsControls.backPayoutMargin',
    defaultMessage: 'Back payout margin',
  },
  layPayoutMargin: {
    id: 'Exchange.ExchangeSettingsControls.layPayoutMargin',
    defaultMessage: 'Lay payout margin',
  },
  layMaxStakeInPlayLowOdd: {
    id: 'Exchange.ExchangeSettingsControls.layMaxStakeInPlayLowOdd',
    defaultMessage:
      'Lay max-stake In-play ({maxStakeMinNumberLowOdd} - {maxStakeMaxNumberLowOdd})',
  },
  layMaxStakeInPlayHighOdd: {
    id: 'Exchange.ExchangeSettingsControls.layMaxStakeInPlayHighOdd',
    defaultMessage: 'Lay max-stake In-play ({maxStakeNumberHighOdd}+)',
  },
  backMaxStakeInPlayLowOdd: {
    id: 'Exchange.ExchangeSettingsControls.backMaxStakeInPlayLowOdd',
    defaultMessage:
      'Back max-stake In-play ({maxStakeMinNumberLowOdd} - {maxStakeMaxNumberLowOdd})',
  },
  backMaxStakeInPlayHighOdd: {
    id: 'Exchange.ExchangeSettingsControls.backMaxStakeInPlayHighOdd',
    defaultMessage: 'Back max-stake In-play ({maxStakeNumberHighOdd}+)',
  },
  updateControlsSuccess: {
    id: 'Exchange.ExchangeSettingsControls.updateControlsSuccess',
    defaultMessage: 'Brand controls was successfully updated.',
  },
  maxAmountError: {
    id: 'Exchange.ExchangeSettingsControls.maxAmountError',
    defaultMessage: 'Amount must be only positive number!',
  },
  updateControlsError: {
    id: 'Exchange.ExchangeSettingsControls.updateControlsError',
    defaultMessage: 'An error occurred while updating controls!',
  },
});
