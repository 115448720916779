import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  DataGridProps,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useHistory, useParams } from 'react-router-dom';
import { Timestamp } from '@firebase/firestore-types';
import { GridGroupingValueGetterParams } from '@mui/x-data-grid-premium';
import format from 'date-fns/format';

import {
  DEFAULT_DATE_FORMAT,
  TWELVE_HOUR_TIME_FORMAT,
  UNSETTLED_BET_DEFAULT_VALUE,
} from '../../../common/constants';
import FormattedData from '../../Unknown/FormattedData';
import ActionsBox from '../../Unknown/ActionsBox';
import LinkCell from '../../Unknown/LinkCell';

import messages from './messages';

import {
  BetfairExchangeSizeType,
  ExchangeBetSide,
  RootState,
} from '../../../../types';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import DataGridTable from '../../Unknown/DataGridTable';
import useExchangeBetSideMap from '../../../common/hooks/useExchangeBetSideMap';
import useBetfairExchangeSizeStatusMap from '../../../common/hooks/useBetfairExchangeSizeStatusMap';
import { ExchangeBetOverviewListRow } from '../ExchangeBetOverview/normalizeBetListData';
import { useSelector } from 'react-redux';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

const propertiesColumn = {
  editable: false,
  filterable: false,
  flex: 0.5,
};

type QueryParams = {
  brandId: string;
};

export interface ExtendedDataGridProps extends Omit<DataGridProps, 'columns'> {
  rows: ExchangeBetOverviewListRow[];
}

interface ExchangeBetOverviewListParams {
  dataGridProps: ExtendedDataGridProps;
  isPlayerPage?: boolean;
  pageType: 'player' | 'brand';
}

const ExchangeBetOverviewList: FC<ExchangeBetOverviewListParams> = ({
  dataGridProps,
  isPlayerPage,
  pageType,
}) => {
  const intl = useIntl();

  const exchangeBetSideMap = useExchangeBetSideMap();

  const exchangeSizeStatusMap = useBetfairExchangeSizeStatusMap();

  const { brandId }: QueryParams = useParams();

  const { roles } = useSelector((root: RootState) => root.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const history = useHistory();

  const { grantedPermissions } = useVerifyPermissions([
    `brand/${brandId}/bet/read`,
    `brand/${brandId}/bet/update`,
  ]);

  const onPushToDetailPage = (id: string) => {
    history.push(`/b/${brandId}/betting-overview/${id}`);
  };

  const allColumns: GridColumns = [
    {
      ...propertiesColumn,
      flex: 0.25,
      field: 'id',
      headerName: intl.formatMessage(messages.id),
    },
    {
      ...propertiesColumn,
      flex: 0.45,
      field: 'date',
      headerName: intl.formatMessage(messages.date),
      renderCell: ({ value }: GridRenderCellParams) => {
        if (!value) {
          return null;
        }

        return <FormattedData dateValue={value} />;
      },
      groupingValueGetter: (
        params: GridGroupingValueGetterParams<Timestamp>,
      ) => {
        if (!params.value) {
          return null;
        }

        return format(
          params?.value?.toDate(),
          `${DEFAULT_DATE_FORMAT} - ${TWELVE_HOUR_TIME_FORMAT}`,
        );
      },
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'side',
      headerName: intl.formatMessage(messages.sideLabel),
      valueFormatter: (params: GridValueFormatterParams<ExchangeBetSide>) => {
        const side = params?.value;

        if (!side) {
          return null;
        }

        const label = exchangeBetSideMap?.[side]?.label;

        return label;
      },
    },
    {
      ...propertiesColumn,
      flex: 0.3,
      field: 'settled',
      headerName: intl.formatMessage(messages.settled),
      renderCell: ({ value }: GridRenderCellParams) => {
        if (!value) {
          return null;
        }

        if (value !== UNSETTLED_BET_DEFAULT_VALUE) {
          return <FormattedData dateValue={value} />;
        }

        return value;
      },
    },
    {
      ...propertiesColumn,
      field: 'eventType',
      headerName: intl.formatMessage(messages.eventType),
    },
    {
      ...propertiesColumn,
      field: 'event',
      headerName: intl.formatMessage(messages.event),
    },
    {
      ...propertiesColumn,
      field: 'marketName',
      headerName: intl.formatMessage(messages.market),
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'outcome',
      headerName: intl.formatMessage(messages.selection),
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'price',
      headerName: intl.formatMessage(messages.initialPrice),
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'avp',
      headerName: intl.formatMessage(messages.averagePrice),
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        const avp = params?.value;

        if (!avp) {
          return null;
        }

        return avp.toFixed(2);
      },
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'priceReduce',
      headerName: intl.formatMessage(messages.priceReduction),
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'size',
      headerName: intl.formatMessage(messages.stake),
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'matchedSize',
      headerName: intl.formatMessage(messages.matchedStake),
    },
    {
      ...propertiesColumn,
      field: 'playerId',
      headerName: intl.formatMessage(messages.player),
      renderCell: ({ value }: GridRenderCellParams) => (
        <LinkCell url={`/b/${brandId}/player-overview/${value}`}>
          {value}
        </LinkCell>
      ),
    },
    {
      ...propertiesColumn,
      flex: 0.3,
      field: 'sizeType',
      headerName: intl.formatMessage(messages.status),
      groupingValueGetter: (params) => {
        const status =
          params?.value?.toLocaleLowerCase() as BetfairExchangeSizeType;

        if (!status) {
          return null;
        }

        const label = exchangeSizeStatusMap?.[status]?.label;

        return label;
      },
      valueFormatter: (params) => {
        const status =
          params?.value?.toLocaleLowerCase() as BetfairExchangeSizeType;

        if (!status) {
          return null;
        }

        const label = exchangeSizeStatusMap?.[status]?.label;

        return label;
      },
    },
    {
      ...propertiesColumn,
      flex: 0.2,
      field: 'status',
      headerName: intl.formatMessage(messages.betStatus),
      renderCell: (params) => {
        const betStatus = params?.value as string;

        const { row } = params;

        if (row?.sizeType === 'Settled') {
          return betStatus.toLocaleUpperCase();
        }
        return '-';
      },
    },
    {
      ...propertiesColumn,
      field: 'actions',
      flex: 0,
      groupable: false,
      headerName: intl.formatMessage(messages.actions),
      renderCell: (params) => {
        const { id: rowId } = params;

        const id = rowId.toString();

        const actions = [];

        const isUpdatePermissionGranted =
          !!grantedPermissions?.bet?.update && isSystemAdmin;
        const isReadPermissionGranted = !!grantedPermissions?.bet?.read;

        if (isReadPermissionGranted) {
          const readAction = {
            label: intl.formatMessage(messages.viewBet),
            buttonProps: {
              onClick: () => onPushToDetailPage(id),
            },
          };

          actions.push(readAction);
        }

        if (isUpdatePermissionGranted) {
          const updateAction = {
            label: intl.formatMessage(messages.editBet),
            buttonProps: {
              onClick: () => onPushToDetailPage(id),
            },
          };

          actions.push(updateAction);
        }

        return <ActionsBox actions={actions} />;
      },
    },
  ];

  const playerColumns = allColumns.filter(({ field }) => field !== 'player');

  const columns = isPlayerPage ? playerColumns : allColumns;

  return (
    <DataGridTable
      {...dataGridProps}
      tableKey={`betting-${pageType}-overview`}
      rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
      disableSelectionOnClick={true}
      columns={columns}
      autoHeight={true}
      rowHeight={35}
      headerHeight={35}
      pagination
      hideFooterRowCount
      hideFooterSelectedRowCount
    />
  );
};

export default ExchangeBetOverviewList;
