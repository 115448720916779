import React, {
  SetStateAction,
  Dispatch,
  useContext,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import {
  GridColumns,
  GridRenderCellParams,
  GridEventListener,
  GridEvents,
  GridApi,
  GridRowData,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';
import { UIContext } from '@miyagami-com/lsx-ui-components';

import { DEFAULT_REGION, LIMIT_LEVELS } from '../../../common/constants';

import LimitLevelCell, {
  limitLevelValueFormatter,
} from '../../Unknown/LimitLevelCell';
import DataGridTable from '../../Unknown/DataGridTable';
import messages from './messages';
import normalizeLimitLevelsData from './normalizeLimitLevelsData';
import getCurrentRow from './getCurrentRow';
import checkEqualsRows from './checkEqualsRows';
import useVerifyPermissions from '../../../common/hooks/useVerifyPermissions';
import renderNumberCell from './renderNumberCell';
import renderEditNumberCell from './renderEditNumberCell';
import useBrandLimits from '../../../common/hooks/useBrandLimits';
import { LimitLevel } from '../../../../types';
import convertRowToBrandLimit from './convertRowToBrandLimit';
import { getFunctions, httpsCallable } from 'firebase/functions';

const defaultColumnProps = {
  filterable: true,
  flex: 1,
};

const limitLevelsOptions = Object.keys(LIMIT_LEVELS);

const defaultColumnLimitLevelProps = {
  ...defaultColumnProps,
  renderCell: (params: GridRenderCellParams<LimitLevel>) => (
    <LimitLevelCell params={params} />
  ),
  valueOptions: limitLevelsOptions,
  valueFormatter: limitLevelValueFormatter,
  type: 'singleSelect',
};

interface LimitControlOverviewProps {
  setDefaultRows: Dispatch<SetStateAction<GridRowData[]>>;
  rows: GridRowData[];
  brandId: string;
  onLoadingDataChange: Dispatch<SetStateAction<boolean>>;
}

const LimitControlOverview: React.FC<LimitControlOverviewProps> = ({
  setDefaultRows,
  rows,
  brandId,
  onLoadingDataChange,
}) => {
  const { setAlert } = useContext(UIContext);

  const intl = useIntl();
  const dataGridApi = useRef<GridApi>();

  const { data: brandLimits, status, refetch } = useBrandLimits({ brandId });

  const { isSuspended, isBrandSuspended } = useVerifyPermissions();

  const isDisabled = isSuspended || isBrandSuspended;

  useEffect(() => {
    const normalizedLimitLevels = normalizeLimitLevelsData({
      brandLimits: brandLimits,
    });

    setDefaultRows(normalizedLimitLevels);
  }, [brandId, brandLimits, setDefaultRows]);

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const onCellEditCommit: GridEventListener<GridEvents.cellEditCommit> = async (
    params,
  ) => {
    onLoadingDataChange(true);
    const { field } = params;

    const currentRow = getCurrentRow({ params, dataGridApi });

    const updateConfig = httpsCallable(
      functions,
      'back-brandConfig-updateBrandLimitLevelsConfig',
    );

    try {
      const isEqualRow = checkEqualsRows({
        field,
        rows,
        equalObject: currentRow,
      });

      if (isEqualRow) {
        setAlert({
          show: true,
          severity: 'info',
          message: intl.formatMessage(messages.alreadyExists),
        });
        return;
      }

      const brandLimit = convertRowToBrandLimit({ row: currentRow });

      await updateConfig({ payload: brandLimit, brandId });
      setAlert({
        show: true,
        severity: 'success',
        message: intl.formatMessage(messages.successUpdating),
      });
    } catch (error) {
      setAlert({
        show: true,
        severity: 'error',
        message: intl.formatMessage(messages.errorUpdating),
      });
    } finally {
      refetch();
      onLoadingDataChange(false);
    }
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'brandId',
        headerName: intl.formatMessage(messages.id),
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<string>) => {
          const { api: gridApi, value } = params;

          dataGridApi.current = gridApi;

          return value;
        },
      },
      {
        field: 'sportLimitLevel',
        headerName: intl.formatMessage(messages.sport),
        editable: !isDisabled,
        ...defaultColumnLimitLevelProps,
      },
      {
        field: 'leagueLimitLevel',
        headerName: intl.formatMessage(messages.league),
        editable: !isDisabled,
        ...defaultColumnLimitLevelProps,
      },
      {
        field: 'marketTypeLimitLevel',
        headerName: intl.formatMessage(messages.type),
        editable: !isDisabled,
        ...defaultColumnLimitLevelProps,
      },
      {
        field: 'playerLimitLevel',
        headerName: intl.formatMessage(messages.player),
        editable: !isDisabled,
        ...defaultColumnLimitLevelProps,
      },
      {
        field: 'maxStakeAmount',
        groupable: false,
        headerName: intl.formatMessage(messages.maxStake),
        ...defaultColumnProps,
        editable: !isDisabled,
        type: 'number',
        align: 'left',
        renderCell: renderNumberCell,
        renderEditCell: renderEditNumberCell,
        flex: 3,
      },
      {
        field: 'maxWinAmount',
        groupable: false,
        headerName: intl.formatMessage(messages.maxWin),
        ...defaultColumnProps,
        editable: !isDisabled,
        type: 'number',
        align: 'left',
        renderCell: renderNumberCell,
        renderEditCell: renderEditNumberCell,
        flex: 3,
      },
    ],
    [intl, isDisabled],
  );

  return (
    <DataGridTable
      columns={columns}
      loading={status === 'loading'}
      tableKey="limit-control"
      rows={rows}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
      onCellEditCommit={onCellEditCommit}
      pageSize={100}
    />
  );
};

export default LimitControlOverview;
