import { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { GridEventListener } from '@mui/x-data-grid-premium';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { useIntl } from 'react-intl';
import messages from './messages';
import { BackofficeUserRole } from '../../../../types';

interface UseOnCellEditCommitParams {
  brandId: string;
  role: BackofficeUserRole;
  playerId?: string;
}

interface Row {
  id: string;
  dateRange: string;
}

const useOnCellEditCommit = (
  hookParams: UseOnCellEditCommitParams,
  rows: Row[],
): GridEventListener<'cellEditCommit'> => {
  const { brandId, role, playerId } = hookParams;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const queryClient = useQueryClient();

  const { setAlert } = useContext(UIContext);
  const intl = useIntl();

  const updateDateRangeConfigMutation = useMutation(
    async (params: {
      brandId: string;
      ref: string;
      isDisabled: boolean;
      role: BackofficeUserRole;
      playerId?: string;
    }) => {
      const updateDateRangeConfig = httpsCallable(
        functions,
        'back-exchangeConfig-callUpdateDateRangeConfig',
      );

      await updateDateRangeConfig(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['dateRangeConfig', brandId, playerId]);
      },
    },
  );

  const onCellEditCommit = useCallback(
    async ({ id, value }) => {
      try {
        const row = rows.find((iterableRow) => iterableRow.id === id);
        const field = row?.dateRange;

        await updateDateRangeConfigMutation.mutateAsync({
          brandId,
          ref: field === 'In Play' ? 'inPlay' : field?.toLowerCase() || '',
          isDisabled: value,
          role,
          playerId,
        });

        setAlert({
          message: intl.formatMessage(messages.successUpdate),
          show: true,
          severity: 'success',
        });
      } catch (error) {
        setAlert({
          message: intl.formatMessage(messages.errorUpdate),
          show: true,
          severity: 'error',
        });
      }
    },
    [
      brandId,
      intl,
      playerId,
      role,
      rows,
      setAlert,
      updateDateRangeConfigMutation,
    ],
  );

  return onCellEditCommit;
};

export default useOnCellEditCommit;
