import { BrandLimit } from '../../../../types/supabase';

interface NormalizeLimitLevelsDataParams {
  brandLimits?: BrandLimit[] | null;
}

export type NormalizedLimitLevel = {
  id: string;
  brandId: string;
  sportLimitLevel: string;
  leagueLimitLevel: string;
  marketTypeLimitLevel: string;
  playerLimitLevel: string;
  maxStakeAmount: number;
  maxWinAmount: number;
};

const normalizeLimitLevelsData = ({
  brandLimits,
}: NormalizeLimitLevelsDataParams): NormalizedLimitLevel[] => {
  if (!brandLimits?.length) return [];

  const normalizedBrandLimits = brandLimits?.map<NormalizedLimitLevel>(
    (brandLimit) => {
      return {
        id: brandLimit.id,
        brandId: brandLimit.brand_id || '',
        sportLimitLevel: brandLimit.event_type_risk_level,
        leagueLimitLevel: brandLimit.competition_risk_level,
        marketTypeLimitLevel: brandLimit.market_risk_level,
        playerLimitLevel: brandLimit.player_risk_level,
        maxStakeAmount: brandLimit.max_stake_amount,
        maxWinAmount: brandLimit.max_win_amount,
      };
    },
  );

  return normalizedBrandLimits;
};

export default normalizeLimitLevelsData;
