import { useQuery } from 'react-query';
import { BrandControl } from '../../../types/supabase';
import useSupabase from './useSupabase';

interface UseBrandControlParams {
  brandId: string;
  select?: string;
}

const useBrandControl = <T extends BrandControl>(
  params: UseBrandControlParams,
): typeof queryResult => {
  const { brandId, select = '*' } = params;

  const supabase = useSupabase();

  const queryResult = useQuery<T | null, Error>(
    ['brandControl', brandId],
    async () => {
      const response = await supabase
        .from('brand_controls')
        .select<typeof select, T>(select)
        .eq('id', brandId)
        .eq('brand_id', brandId)
        .maybeSingle();

      return response.data;
    },
  );

  return queryResult;
};

export default useBrandControl;
