import { DEFAULT_FONT } from '../../../common/constants';

const fonts = [
  DEFAULT_FONT,
  {
    label: 'Sans-serif',
    value:
      'MS Trebuchet, ITC Avant Garde Gothic, MS Arial, MS Verdana, Univers, Futura, ITC Stone Sans, Gill Sans, Akzidenz Grotesk, Helvetica',
  },
  {
    label: 'Monospace',
    value: 'Courier, MS Courier New, Prestige, Everson Mono',
  },
  {
    label: 'Cursive',
    value:
      'Caflisch Script, Adobe Poetica, Sanvito, Ex Ponto, Snell Roundhand, Zapf-Chancery',
  },
  {
    label: 'Fantasy',
    value: 'fantasy, Comic Sans Ms, Cracked, Curlz MT',
  },
  {
    label: 'FangSong',
    value: 'fangsong',
  },
  {
    label: 'Roboto Slab',
    value: '"Roboto Slab", serif',
  },
];

export default fonts;
