import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  GridEnrichedColDef,
  GridColumns,
} from '@miyagami-com/lsx-ui-components';
import messages from './messages';
import renderIsDisabledCell from './renderIsDisabledCell';

const defaultColumnParams: Partial<GridEnrichedColDef> = {
  editable: false,
  filterable: false,
  hideSortIcons: true,
  disableColumnMenu: true,
  align: 'left',
  headerAlign: 'left',
};

const useColumns = (): GridColumns => {
  const intl = useIntl();

  const columns: GridColumns = useMemo(() => {
    return [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'dateRange',
        headerName: intl.formatMessage(messages.dateRange),
        flex: 1,
        ...defaultColumnParams,
      },
      {
        field: 'isDisabled',
        headerName: intl.formatMessage(messages.enable),
        flex: 1,
        ...defaultColumnParams,
        renderCell: renderIsDisabledCell,
        renderEditCell: renderIsDisabledCell,
      },
    ];
  }, [intl]);

  return columns;
};

export default useColumns;
